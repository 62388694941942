import React, { useState } from 'react'
import { data } from '../../../data/data';
import { Link } from 'react-router-dom';


function Structure() {
    const tableauBody = document.getElementsByClassName('tableau-body')

    const [isTableVisible, setTableVisible] = useState(true);

    const toggleTable = () => {
        if (isTableVisible) {
            tableauBody[0].style.height = '253px'
            tableauBody[0].style.opacity = '1'
            setTimeout(() => {
                tableauBody[0].style.height = 'fit-content'
                const tableauHeight = tableauBody[0].offsetHeight
                tableauBody[0].style.height = tableauHeight + "px"
            }, 300)
        }
        else {
            tableauBody[0].style.opacity = '0'
            tableauBody[0].style.height = '0px'
        }
        setTableVisible(!isTableVisible);
    }

    // Filtrer les produits de la catégorie 'amenagement'
    const amenagementProducts = data
        .filter(product => product.categorie.includes('structure'))
        .sort((a, b) => a.name.localeCompare(b.name));
    return (
        <div className='structure'>
            <h1><Link to="/bois-de-charpente">&#x2B05;</Link>Bois de structure</h1>
            <fleche className='fleche-div'>
                <Link to="/bois-de-charpente" className='fleche'>⬅</Link>
            </fleche>
            <table className='tableau' onClick={toggleTable}>
                <thead>
                    <tr>
                        <td colspan="5"> Tableau de comparaison </td>
                    </tr>
                </thead>
                <tbody className='tableau-body'>
                    <tr>
                        <td>Produit</td>
                        <td>Section (En cm)</td>
                        <td>Longeur (En m)</td>
                        <td>Tarif en Euros TTC au m3 en Sapin</td>
                        <td>Tarif en Euros TTC au m3 en Douglas</td>
                    </tr>
                    <tr>
                        <td>Bastaing</td>
                        <td>6x18</td>
                        <td>3 à 6</td>
                        <td>384</td>
                        <td>528</td>
                    </tr>
                    <tr>
                        <td>Chevron</td>
                        <td>6x8</td>
                        <td>3 à 6</td>
                        <td>384</td>
                        <td>528</td>
                    </tr>
                    <tr>
                        <td>Lambourde</td>
                        <td>6x4</td>
                        <td>3 à 5</td>
                        <td>384</td>
                        <td>528</td>
                    </tr>
                    <tr>
                        <td>Liteau</td>
                        <td>2.7x4</td>
                        <td>3 à 4</td>
                        <td>408</td>
                        <td>552</td>
                    </tr>
                    <tr>
                        <td>Plateau Maçon</td>
                        <td>5x25</td>
                        <td>4</td>
                        <td>480</td>
                        <td>X</td>
                    </tr>
                    <tr>
                        <td>Triangle</td>
                        <td>10x10</td>
                        <td>3 et 4</td>
                        <td>408</td>
                        <td>552</td>
                    </tr>
                </tbody>
            </table>
            <div className='cartes'>
                {amenagementProducts.map((product, index) => (
                    <div key={index} className='carte-produit'>
                        <img src={product.imageUrl} alt={product.name} />
                        <div>
                            <h2>{product.name}</h2>
                            <p>Essence : <span>{product.essence}</span></p>
                            <p>Epaisseur : <span>{product.epaisseur}cm</span></p>
                            <p>Largeur : <span>{product.largeur}cm</span></p>
                            <p>Longueur : <span>{product.longeur}m</span></p>
                            <p>Qualité/Finition : <span>{product.qualite}</span></p>
                            {product.prix && product.prix !== "" && (
                                <p>Prix TTC au ml en Douglas : <span>{product.prix}€</span></p>
                            )}
                            {product.prixSec && product.prixSec !== "" && (
                                <p>Prix TTC au ml en Sapin : <span>{product.prixSec}€</span></p>
                            )}
                            {product.prixUni && product.prixUni !== "" && (
                                <p>Prix à l'unité en Sapin : <span>{product.prixUni}€</span></p>
                            )}
                            <a href={product.fiche} target='_blank' rel="noreferrer" className='devis'>Fiche Technique</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Structure