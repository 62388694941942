import React, { useState } from 'react'
import { data } from '../../data/data';
import { Link } from 'react-router-dom';

function AmenagementInterExter() {
    // Filtrer les produits de la catégorie 'amenagement'
    const tableauBody = document.getElementsByClassName('tableau-body')

    const [isTableVisible, setTableVisible] = useState(true);

        const toggleTable = () => { 
            if(isTableVisible){
                tableauBody[0].style.height = '799px'
                tableauBody[0].style.opacity = '1'
                setTimeout(() => {
                    tableauBody[0].style.height = 'fit-content'
                    const tableauHeight = tableauBody[0].offsetHeight
                    tableauBody[0].style.height = tableauHeight + "px"
                },300)
                    
                
            }
            else{
                tableauBody[0].style.opacity = '0'
                tableauBody[0].style.height = '0px'
            }
            setTableVisible(!isTableVisible);
        }; 

    const amenagementProducts = data
        .filter(product => product.categorie.includes('amenagement'))
        .sort((a, b) => a.name.localeCompare(b.name));
    return (
        <div className='amenagement'>
            <h1><Link to="/produits">&#x2B05;</Link>Produits d'aménagement intérieur et extérieur</h1>
            <fleche className='fleche-div'>
                <Link to="/produits" className='fleche'>⬅</Link>
            </fleche>
            <table className='tableau' onClick={toggleTable}>
                <thead>
                    <tr>
                        <td colSpan="3"> Tableau de comparaison</td>
                    </tr>
                </thead>
                <tbody className='tableau-body'>
                    <tr>
                        <th rowSpan="2">Produit</th>
                        <th rowSpan="2">Dimension (En cm)</th>
                        <th rowSpan="2">Prix au m2 TTC</th>
                    </tr>

                    <tr>

                    </tr>

                    <tr>
                        <td rowSpan="4">Plancher en Douglas</td>
                        <td>2.2x10</td>
                        <td rowSpan="2">24.6 €</td>
                    </tr>
                    <tr>
                        <td>2.2x15</td>
                    </tr>
                    <tr>
                        <td>2.2x15 <span>Déclassé</span></td>
                        <td>16.8 €</td>
                    </tr>
                    <tr>
                        <td>3.5x15</td>
                        <td>34.2 €</td>
                    </tr>
                    <tr>
                        <td rowSpan="3">Bardage Horizontal ou Vertical Douglas</td>
                        <td>2.2x10</td>
                        <td rowSpan="2">24.6 €</td>
                    </tr>

                    <tr>
                        <td>2.2x15</td>
                    </tr>

                    <tr>
                        <td>3.5x15</td>
                        <td>34.2 €</td>
                    </tr>

                    <tr>
                        <td rowSpan="3">Lame Terrasse ou Piscine en Douglas</td>
                        <td>2.2x11</td>
                        <td rowSpan="2">24.6 €</td>
                    </tr>

                    <tr>
                        <td>2.2x16</td>
                    </tr>

                    <tr>
                        <td>3.5x16</td>
                        <td>34.2 €</td>
                    </tr>
                    <tr>
                        <td> Lame Mixte ou Bombée en Douglas </td>
                        <td> 2.7x12 </td>
                        <td> 28.2 € </td>
                    </tr>
                    <tr>
                        <td> Lame Barrière en Douglas </td>
                        <td> 2.1x9 </td>
                        <td> 17.4 € </td>
                    </tr>
                    <tr>
                        <td rowSpan="2">Lame Multi-Usages en Douglas</td>
                        <td>2.2x11</td>
                        <td rowSpan="2">16.8 €</td>
                    </tr>

                    <tr>
                        <td>2.2x16</td>
                    </tr>

                    <tr>
                        <td>Bardage horizontal en Pin Thermo-Chauffé</td>
                        <td>2.1x14.5</td>
                        <td>34.8 €</td>
                    </tr>

                    <tr>
                        <td rowSpan="2">Lame Terrasse en Pin Thermo-Chauffé</td>
                        <td>2.1x15</td>
                        <td rowSpan="2">34.8 €</td>
                    </tr>

                    <tr>
                        <td>2.1x10.7</td>
                    </tr>

                    <tr>
                        <td>Lame Mixte ou Bombée en Pin Thermo-Chauffé</td>
                        <td>2.6x11.6</td>
                        <td>42 €</td>
                    </tr>
                </tbody>
            </table>
            <div className='cartes'>
                {amenagementProducts.map((product, index) => (
                    <div key={index} className='carte-produit'>
                        <img src={product.imageUrl} alt={product.name} />
                        <div>
                            <h2>{product.name}</h2>
                            <p>Epaisseur : <span>{product.epaisseur}cm</span></p>
                            <p>Largeur : <span>{product.largeur}cm</span></p>
                            <p>Longueur : <span>{product.longeur}m</span></p>
                            <p>Durabilité : <span>{product.classe}</span></p>
                            <p>Prix TTC au m2 : <span>{product.prix}€</span></p>
                            <a href={product.fiche} target='_blank' rel="noreferrer" className='devis'>Fiche Technique</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AmenagementInterExter