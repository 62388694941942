export const data = [

    //
    // Aménagement
    //

    {
        categorie: ['amenagement'],
        name: 'Lame Terrasse Bombée Pin Thermo-Chauffé',
        imageUrl: '/images/amenagement/Lame Bombee Thermo.webp',
        epaisseur: '2.6',
        largeur: '11.6',
        longeur: '4',
        classe: 'Classe 4',
        prix: '42',
        fiche: '/pdf/amenagement/Fiche technique Lame Terrasse Bombée Thermo.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Lame Terrasse Bombée',
        imageUrl: '/images/amenagement/Lame Bombee.webp',
        epaisseur: '2.7',
        largeur: '12',
        longeur: '4',
        classe: 'Classe 3',
        prix: '28.2',
        fiche: '/pdf/amenagement/Fiche technique Lame Terrasse Bombee.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Bardage Horizontal en Douglas',
        imageUrl: '/images/amenagement/Bardage H Douglas.webp',
        epaisseur: '2.2',
        largeur: '10 et 15',
        longeur: '2.5 / 3 / 4',
        classe: 'Classe 3',
        prix: '24.6',
        fiche: '/pdf/amenagement/Fiche technique Bardage Horizontal.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Bardage Vertical en Douglas',
        imageUrl: '/images/amenagement/Bardage-vertical-Douglas.webp',
        epaisseur: '2.2',
        largeur: '10 et 15',
        longeur: '2.5 / 3 / 4',
        classe: 'Classe 3',
        prix: '24.6',
        fiche: '/pdf/amenagement/Fiche technique Bardage Vertical.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Plancher en Douglas',
        imageUrl: '/images/amenagement/Plancher d.webp',
        epaisseur: '2.2',
        largeur: '10 et 15',
        longeur: '2.5 / 3 / 4',
        classe: 'Classe 3',
        prix: '24.6',
        fiche: '/pdf/amenagement/Fiche technique Plancher.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Bardage Horizontal Pin Thermo-Chauffé',
        imageUrl: '/images/amenagement/Bardage-H-Thermo.webp',
        epaisseur: '2.1',
        largeur: '14.5',
        longeur: '3',
        classe: 'Classe 4',
        prix: '34.8',
        fiche: '/pdf/amenagement/Fiche technique Bardage Horizontal Thermo.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Lame Terrasse en Douglas',
        imageUrl: '/images/amenagement/Lame terrasse.webp',
        epaisseur: '2.2',
        largeur: '11 et 16',
        longeur: '2.5 / 3 / 4',
        classe: 'Classe 3',
        prix: '24.6',
        fiche: '/pdf/amenagement/Fiche technique Lame Terrasse.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Lame Terrasse en Pin Thermo-Chauffé',
        imageUrl: '/images/amenagement/Lame terrasse Thermo.webp',
        epaisseur: '2.1',
        largeur: '10.7 et 15',
        longeur: '3',
        classe: 'Classe 4',
        prix: '34.8',
        fiche: '/pdf/amenagement/Fiche technique Lame Terrasse Thermo.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Lame Mixte en Douglas',
        imageUrl: '/images/amenagement/Lame-mixte-Douglas.webp',
        epaisseur: '2.7',
        largeur: '12',
        longeur: '4',
        classe: 'Classe 3',
        prix: '28.2',
        fiche: '/pdf/amenagement/Fiche technique Lame Mixte.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Lame Mixte en Pin Thermo-Chauffé',
        // Photo Lame Mixte Thermo
        imageUrl: '/images/amenagement/Lame mixte Thermo.webp',
        epaisseur: '2.6',
        largeur: '12',
        longeur: '4',
        classe: 'Classe 4',
        prix: '42',
        fiche: '/pdf/amenagement/Fiche technique Lame Mixte Thermo.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Lame Piscine en Douglas',
        imageUrl: '/images/amenagement/lame piscine.webp',
        epaisseur: '2.2',
        largeur: '11 et 16',
        longeur: '2.5 / 3 / 4',
        classe: 'Classe 3',
        prix: '24.6',
        fiche: '/pdf/amenagement/Fiche technique Lame Piscine.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Lame Barriere en Douglas',
        imageUrl: '/images/amenagement/Lame-barrière.webp',
        epaisseur: '2.1',
        largeur: '9',
        longeur: '4',
        classe: 'Classe 3',
        prix: '17.4',
        fiche: '/pdf/amenagement/Fiche technique Lame Barriere.pdf'
    },
    {
        categorie: ['amenagement'],
        name: 'Lame Multi-usages en Douglas',
        imageUrl: '/images/amenagement/lame mutli usage.webp',
        epaisseur: '2.3',
        largeur: '11 et 16',
        longeur: '3',
        classe: 'Classe 3',
        prix: '16.8',
        fiche: '/pdf/amenagement/Fiche technique Lame multi-usages.pdf'
    },

    //
    // Structure
    //
    {
        categorie: ['structure'],
        name: 'Triangle',
        imageUrl: '/images/surmesure/Triangle.webp',
        essence: 'Sapin ou Douglas',
        epaisseur: '10',
        largeur: '10',
        longeur: '(Tous les 0.5m) 3 et 4',
        qualite: ' C18 / Brut',
        prix: '2.76',
        prixSec: '2.04',
        fiche: '/pdf/surmesure/Triangle.pdf',
    },
    {
        categorie: ['structure'],
        name: 'Plateau Maçon',
        imageUrl: '/images/surmesure/Plateau macon.webp',
        essence: 'Sapin',
        epaisseur: '5',
        largeur: '25',
        longeur: '4',
        qualite: ' C24 / Brut',
        prixUni: '24',
        fiche: '/pdf/surmesure/Plateau Macon.pdf',
    },
    {
        categorie: ['structure'],
        name: 'Bastaing',
        imageUrl: '/images/surmesure/Bastaing.webp',
        essence: 'Sapin ou Douglas',
        epaisseur: '6',
        largeur: '18',
        longeur: '(Tous les 0.5m) 3 à 6',
        qualite: ' C18 / Brut',
        prix: '5.7',
        prixSec: '4.14',
        fiche: '/pdf/surmesure/Bastaing.pdf',
    },
    {
        categorie: ['structure'],
        name: 'Chevron',
        imageUrl: '/images/surmesure/Chevron.webp',
        essence: 'Sapin ou Douglas',
        epaisseur: '6',
        largeur: '8',
        longeur: '(Tous les 0.5m) 3 à 6',
        qualite: ' C18 / Brut',
        prix: '2.54',
        prixSec: '1.84',
        fiche: '/pdf/surmesure/Chevron.pdf',
    },
    {
        categorie: ['structure'],
        name: 'Lambourde',
        imageUrl: '/images/surmesure/Lambourde.webp',
        essence: 'Sapin ou Douglas',
        epaisseur: '4',
        largeur: '6',
        longeur: '3 à 4',
        qualite: ' C18 / Brut',
        prix: '1.27',
        prixSec: '0.92',
        fiche: '/pdf/surmesure/Lambourde.pdf',
    },
    {
        categorie: ['structure'],
        name: 'Liteau',
        imageUrl: '/images/surmesure/Liteau.webp',
        essence: 'Sapin ou Douglas',
        epaisseur: '2.7',
        largeur: '4',
        longeur: '3 à 4',
        qualite: ' C18 / Brut',
        prix: '0.6',
        prixSec: '0.44',
        fiche: '/pdf/surmesure/Liteau.pdf',
    },
    //
    // Ossature
    //
    {
        categorie: ['ossature'],
        name: "Bois d'ossature",
        imageUrl: '/images/surmesure/Bois dossature Douglas 4.5x14.5.webp',
        essence: 'Douglas ',
        epaisseur: '4.5',
        largeur: '14.5',
        longeur: '3, 4 et 5',
        qualite: ' C18 / Calibré / 4 angles arrondis',
        prix: '4.78',
        fiche: "/pdf/surmesure/Bois d'ossature Douglas.pdf",
    },
    {
        categorie: ['ossature'],
        name: "Bois d'ossature",
        imageUrl: '/images/surmesure/Bois dossature 4.5x12 en Douglas.webp',
        essence: 'Douglas ',
        epaisseur: '4.5',
        largeur: '12',
        longeur: '4 et 5',
        qualite: ' C18 / Calibré / 4 angles arrondis',
        prix: '4',
        fiche: "/pdf/surmesure/Bois d'ossature Douglas.pdf",
    },
    {
        categorie: ['ossature'],
        name: "Bois d'ossature",
        imageUrl: '/images/surmesure/Bois dossature 4.5x9.5 en Douglas.webp',
        essence: 'Douglas ',
        epaisseur: '4.5',
        largeur: '9.5',
        longeur: '4 et 5',
        qualite: ' C18 / Calibré / 4 angles arrondis',
        prix: '3.23',
        fiche: "/pdf/surmesure/Bois d'ossature Douglas.pdf",
    },
    {
        categorie: ['ossature'],
        name: "Bois d'ossature",
        imageUrl: '/images/surmesure/Bois dossature 4.5x22 Douglas.webp',
        essence: 'Douglas ',
        epaisseur: '4.5',
        largeur: '22',
        longeur: '4 et 5',
        qualite: ' C18 / Calibré / 4 angles arrondis',
        prix: '7.48',
        fiche: "/pdf/surmesure/Bois d'ossature Douglas.pdf",
    },
    //
    // Coffrage
    //
    {
        categorie: ['coffrage'],
        name: "Planche de Coffrage",
        imageUrl: '/images/surmesure/Planche coffrage.webp',
        essence: 'Sapin / Pin / Douglas',
        epaisseur: '2.7',
        largeur: 'variant de 10 à 19',
        longeur: '2.5 / 3 / 4',
        qualite: ' Palette / Brut',
        prix: '7.2',
        fiche: '/pdf/surmesure/Planche de Coffrage.pdf',
    },
    {
        categorie: ['coffrage'],
        name: "Volige Sapin",
        imageUrl: '/images/surmesure/volige sapin.webp',
        essence: 'Sapin',
        epaisseur: '1.5',
        largeurFixe: '10 à 20',
        touteLargeur: 'variant de 8 à 20',
        longeur: '(Tous les 0.5m) 2 à 4',
        qualite: ' Palette / Brut',
        prix: 'Toute largeur: 6',
        prixSec: 'Largeur fixe: 7.2',
        fiche: '/pdf/surmesure/Volige Sapin.pdf',
    },
    {
        categorie: ['coffrage'],
        name: "Volige Douglas",
        imageUrl: '/images/surmesure/volige douglas.webp',
        essence: 'Douglas',
        epaisseur: '1.5',
        largeurFixe: '10 à 20',
        touteLargeur: 'variant de 8 à 20',
        longeur: '(Tous les 0.5m) 2 à 4',
        qualite: ' Palette / Brut',
        prix: 'Toute largeur: 8.4',
        prixSec: 'Largeur fixe: 9',
        fiche: '/pdf/surmesure/Volige Douglas.pdf',
    },
    {
        categorie: ['coffrage'],
        name: "Planche de dosse",
        imageUrl: '/images/surmesure/dosse.webp',
        essence: 'Sapin / Pin / Douglas',
        epaisseur: '2 à 4',
        largeur: '10',
        longeur: '3 et 4',
        qualite: ' Palette / Brut',
        prix: '7.2',
        fiche: '/pdf/surmesure/Planche de Dosse.pdf',
    },
    //
    // charpente
    //
    {
        categorie: ['charpente'],
        name: "Planche de Charpente Douglas",
        imageUrl: '/images/surmesure/Planche Charpente Douglas.webp',
        essence: 'Douglas',
        epaisseur: '2.7',
        largeur: '10 à 25',
        longeur: '(Tous les 0.5m) 2.5 à 4',
        qualite: ' C18 / Brut',
        prix: '16.2',
        fiche: '/pdf/surmesure/Planche de Charpente Douglas.pdf',
    },
    {
        categorie: ['charpente'],
        name: "Planche de Charpente Sapin",
        imageUrl: '/images/surmesure/planche sapin.webp',
        essence: 'Sapin',
        epaisseur: '2.7',
        largeur: '12, 17 et 20',
        longeur: '(Tous les 0.5m) 2.5 à 4',
        qualite: ' C18 / Brut',
        prixSec: '11.4',
        fiche: '/pdf/surmesure/Planche de Charpente Sapin.pdf',
    },
    {
        categorie: ['charpente'],
        name: "Planche sèche",
        imageUrl: '/images/surmesure/Planche seche.webp',
        essence: 'Douglas',
        epaisseur: '2.7 / 4',
        largeur: '12 / 17',
        longeur: '2.5 / 3 / 4',
        qualite: ' C18 / Brut',
        prix: '19.8',
        fiche: '/pdf/surmesure/Planche seche Douglas.pdf',
    },
    {
        categorie: ['charpente'],
        name: "Planche sèche Thermo",
        imageUrl: '/images/surmesure/Planche sèche Thermo.webp',
        essence: 'Pin Thermo-Chauffé',
        epaisseur: '2.6',
        largeur: '11.5 / 16.5',
        longeur: '3',
        qualite: ' Brut',
        prix: '22.2',
        fiche: '/pdf/surmesure/Planche sèche Pin Thermo.pdf',
    },

]